import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ReviewsSlider from "../components/reviews-slider";
import ToolsLtr from "../components/tools-ltr";
import ToolsRtl from "../components/tools-rtl";
import { StaticImage } from "gatsby-plugin-image";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { Contact, Popup } from "../components/popup";
import CustomMap from "../components/custom-map";
import OfferSlider from "../components/offer-slider";
import CustomGraph from "../components/custom-graph";
import CustomGraphResponsive from "../components/custom-graph-responsive";

const ServiceDynamicPage = ({ pageContext }) => {
  const [popupShow, setPopupShow] = useState(false);
  const [popupShowContact, setPopupShowContact] = useState(false);
  const [getInTouchClicked, setGetInTouchClicked] = useState(false);
  const [componentUpdate, setComponentUpdate] = useState(false);

  const dynamicUrlContent = pageContext;

  const updateComponent = () => {
    setComponentUpdate(!componentUpdate);
  };

  const handlePopupModal = () => {
    setPopupShow(!popupShow);
  };

  const handlePopupModalContact = () => {
    setGetInTouchClicked(!getInTouchClicked);
    setPopupShowContact(!popupShowContact);
    setGetInTouchClicked(!getInTouchClicked);
  };

  return (
    <>
      <Layout>
        <div className="wrapper">
          <section className="serviceHeader enterpriseSolutionsHeader">
            <div className="innerPageNavigation">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/services/">Services</Link>
                </li>
              </ul>
            </div>
            <div className="serviceContainer">
              <div className="dynamicContent">
                <h1> {dynamicUrlContent?.title}</h1>
                <p>{dynamicUrlContent?.content}</p>
                <button
                  className="btn heroBtn"
                  onClick={handlePopupModalContact}
                >
                  Get an estimate
                </button>
              </div>
              <div className="servicePartner">
                <ul>
                  <li>
                    <div className="partnerImg">
                      <Link to="/services/mobility-solutions/">
                        Mobility Solutions&nbsp;
                        <span className="circle">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow.svg"
                          />
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="partnerImg">
                      <Link to="/services/data-solutions/">
                        Data Solutions&nbsp;
                        <span className="circle">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow.svg"
                          />
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="partnerImg">
                      <Link to="/services/cloud-solutions/">
                        Cloud Solutions&nbsp;
                        <span className="circle">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow.svg"
                          />
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="partnerImg">
                      <Link to="/services/qa-testing/">
                        QA & Testing&nbsp;
                        <span className="circle">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow.svg"
                          />
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="partnerImg">
                      <Link to="/services/metaverse-development/">
                        Metaverse Development&nbsp;
                        <span className="circle">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow.svg"
                          />
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="partnerImg">
                      <Link to="/services/startup-development/">
                        Startup Development&nbsp;
                        <span className="circle">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow.svg"
                          />
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="partnerImg">
                      <Link to="/services/game-development/">
                        Game Development&nbsp;
                        <span className="circle">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow.svg"
                          />
                        </span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="serviceDetailsSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <div className="container">
                  <div className="row">
                    <h1 className="col-lg-12">
                      <span className="circle"></span>
                      &nbsp;{dynamicUrlContent?.sub_title1}
                    </h1>
                    <div className="col-lg-6 col-md-6">
                      <div className="serviceDetailsImage">
                        <StaticImage
                          placeholder="none"
                          alt="solutions"
                          className="cardgradient"
                          src="../images/servicedetails/service-details-1.svg"
                        />
                        <StaticImage
                          placeholder="none"
                          alt="solutions"
                          className="mobile-cardgradient"
                          src="../images/servicedetails/service-details-1.svg"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="serviceContent">
                        <p>{dynamicUrlContent?.sub_content1}</p>
                        <p>{dynamicUrlContent?.sub_content2}</p>
                        {dynamicUrlContent?.sub_content3 && (
                          <p>{dynamicUrlContent?.sub_content3}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="workSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>How we work
                </h1>
                <div className="row">
                  <div className="graphDesktop">
                    <CustomGraph
                      handleComponentUpdate={updateComponent}
                      status={componentUpdate}
                    />
                  </div>
                  <div className="graphMobile">
                    <CustomGraphResponsive
                      handleComponentUpdate={updateComponent}
                      status={componentUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="solutionSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <div className="row">
                  <div className="col-lg-6">
                    <h1>
                      <span className="circle"></span>
                      <span className="titleName">
                        {dynamicUrlContent?.choose_title}
                      </span>
                    </h1>
                    <div className="solutionPoint">
                      <div
                        className="valuePoint"
                        data-aos-delay="100"
                        data-aos="zoom-in"
                        data-aos-easing="linear"
                        data-aos-duration="5"
                      >
                        <h5>01</h5>
                        <p> {dynamicUrlContent?.choose_content1}</p>
                      </div>
                      <div
                        className="valuePoint"
                        data-aos-delay="500"
                        data-aos="zoom-in"
                        data-aos-easing="linear"
                        data-aos-duration="10"
                      >
                        <h5>02</h5>
                        <p>{dynamicUrlContent?.choose_content2}</p>
                      </div>
                      <div
                        className="valuePoint"
                        data-aos-delay="1000"
                        data-aos="zoom-in"
                        data-aos-easing="linear"
                        data-aos-duration="15"
                      >
                        <h5>03</h5>
                        <p>{dynamicUrlContent?.choose_content3}</p>
                      </div>
                      <div
                        className="valuePoint"
                        data-aos-delay="1500"
                        data-aos="zoom-in"
                        data-aos-easing="linear"
                        data-aos-duration="20"
                      >
                        <h5>04</h5>
                        <p>{dynamicUrlContent?.choose_content4}</p>
                      </div>
                      <div
                        className="valuePoint"
                        data-aos-delay="2000"
                        data-aos="zoom-in"
                        data-aos-easing="linear"
                        data-aos-duration="25"
                      >
                        <h5>05</h5>
                        <p>{dynamicUrlContent?.choose_content5}</p>
                      </div>
                      {dynamicUrlContent?.choose_content6 && (
                        <div
                          className="valuePoint"
                          data-aos-delay="2000"
                          data-aos="zoom-in"
                          data-aos-easing="linear"
                          data-aos-duration="25"
                        >
                          <h5>06</h5>
                          <p>{dynamicUrlContent?.choose_content6}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="solutionBox"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="serviceofferSection noserviceSlider"
            id={
              dynamicUrlContent?.id
                ? dynamicUrlContent?.id
                : "enterpriseApplicationDevelopment"
            }
          >
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>
                  {dynamicUrlContent?.service_title}
                </h1>
                <div className="testimonialService">
                  <div className="serviceContainer">
                    <div className="authorRow">
                      <OfferSlider
                        data={dynamicUrlContent?.ecommerceService}
                        type="service"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="whomSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>For whom
                </h1>
                <div className="whomContent">
                  <ul>
                    <li>
                      <div className="whomItem">
                        <div className="whomTitle">
                          <h2>For Startups</h2>
                        </div>
                        <div className="whomDirection">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow-whom.svg"
                          />
                        </div>
                        <div className="whomDetails">
                          <div className="forStartup">
                            <div className="whomfor-greater">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>
                              Greater flexibility&nbsp;
                              <p className="hidden">Greater flexibility</p>
                            </h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="200"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-focus">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>
                              Focus on core business&nbsp;
                              <p className="hidden">Greater flexibility</p>
                            </h5>
                          </div>
                          <div
                            className="forStartup forStartup3"
                            data-aos-delay="300"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-reduce">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Reduced development time and cost</h5>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="whomItem">
                        <div className="whomTitle">
                          <h2>For SMB</h2>
                        </div>
                        <div className="whomDirection">
                          <StaticImage
                            placeholder="none"
                            alt="whom"
                            src="../images/arrow-whom.svg"
                          />
                        </div>
                        <div className="whomDetails">
                          <div
                            className="forStartup"
                            data-aos-delay="400"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-inhouse">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>In-house Management</h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="500"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-regulation">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Regulation compliance</h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="600"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-technical">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Technical support</h5>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="whomItem">
                        <div className="whomTitle">
                          <h2>For Enterprise</h2>
                        </div>
                        <div className="whomDirection">
                          <StaticImage
                            placeholder="none"
                            alt="whom"
                            src="../images/arrow-whom.svg"
                          />
                        </div>
                        <div className="whomDetails">
                          <div
                            className="forStartup"
                            data-aos-delay="700"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-mitigating">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Mitigating organizational risk</h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="800"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-certified">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>
                              Certified Engineers&nbsp;
                              <p className="hidden">Certified Engineers</p>
                            </h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="900"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-experience">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Experience in Long-terms projects </h5>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="workSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <div className="journeyContent">
                  <h1>
                    <span className="circle"></span>Long-term journey
                  </h1>
                  <p>
                    We started our journey with the idea of creating innovative
                    products, and we have been following this trend since 2004
                    and continue to build technological solutions for our
                    customers.
                  </p>
                </div>
                <div className="journeyTrack">
                  <StaticImage
                    placeholder="none"
                    className="desktop-view"
                    alt="journeyTrack"
                    src="../images/long-term-journey.webp"
                  />
                  <StaticImage
                    placeholder="none"
                    className="mobile-view"
                    alt="journeyTrack"
                    src="../images/long-term-journey-mobile.webp"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="testimonialsSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Testimonials
                </h1>
                <div className="testimonialService">
                  <div className="container">
                    <div className="authorRow">
                      <div className="authorBox">
                        <div className="testimonialBoxItem">
                          <div className="testimonialBoxContent">
                            <div className="author-box authorBox-1">
                              <span className="authorbox-cover"></span>
                            </div>
                            <div className="testimonialAuthor">
                              <h5>Charles Jacobson</h5>
                              <span>CTO - Strategy and Content, Isentia</span>
                            </div>
                          </div>
                          <p>
                            “We’ve used Xminds at King Content for the past 4
                            years. During this time they’ve provided skillful
                            developers who communicate well and are a pleasure
                            to deal with. Xminds are also very flexible and
                            ready help no matter how short the time-frame is.
                            They’ve made offshore development easy for King
                            Content and I’ll definitely use them again.”
                          </p>
                        </div>
                      </div>
                      <div className="authorBox">
                        <div className="testimonialBoxItem">
                          <div className="testimonialBoxContent">
                            <div className="author-box authorBox-2">
                              <span className="authorbox-cover"></span>
                            </div>
                            <div className="testimonialAuthor">
                              <h5>Jeremy Samuel</h5>
                            </div>
                          </div>

                          <p>
                            "We used XMinds when we were in a whole world of
                            pain… other developers had let us down badly.XMinds
                            came in , did a qa of the existing code, created a
                            handover and completion plan and then set about
                            getting the job done properly.After our first
                            experience, we were pretty burned.By the time XMinds
                            got done, we were very satisfied.They delivered a
                            great result, were fantastic to work with and
                            provided code that could be easily understood and
                            kept updated.I would definitely recommend them for
                            any complex software development project."
                          </p>
                        </div>
                      </div>
                      <div className="authorBox">
                        <div className="testimonialBoxItem">
                          <div className="testimonialBoxContent">
                            <div className="author-box authorBox-3">
                              <span className="authorbox-cover"></span>
                            </div>
                            <div className="testimonialAuthor">
                              <h5>Christiaan Erik Rijnders</h5>
                            </div>
                          </div>
                          <p>
                            “Xminds did a thorough, creative and professional
                            job on the interface of our Augmented Reality Eyes
                            application for Android. The remote integration with
                            the work of our engineers in Italy was seamless and
                            Xminds delivered exactly on time. We look forward to
                            working with Xminds again.”
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="technologiesSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>
                  {dynamicUrlContent?.technology_title}
                </h1>
                <div className="tool-ltr">
                  <ToolsLtr />
                  <ToolsRtl />
                </div>
                <div className="tool-rtl"></div>
              </div>
            </div>
          </section>
          <section className="marketplaceSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Our reviews on Marketplaces
                </h1>
                <div className="marketContainer">
                  <div className="marketRow">
                    <div className="marketLeft">
                      <div className="content">
                        <p>
                          We practice what we preach. See what our clients have
                          to say about us
                        </p>
                        <a
                          target="_blank"
                          href="https://clutch.co/profile/xminds-infotech#summary"
                          className="btn"
                          rel="noreferrer"
                        >
                          Read more reviews&nbsp;
                        </a>
                        <div className="awardBox">
                          <h4>
                            Recognized on Clutch as a Top Web Developer in India
                          </h4>
                          <div className="awardList">
                            <div className="awardListBox">
                              <span className="awards-1"></span>
                            </div>
                            <div className="awardListBox">
                              <span className="awards-2"></span>
                            </div>
                            <div className="awardListBox">
                              <span className="awards-3"></span>
                            </div>
                            <div className="awardListBox">
                              <span className="awards-4"></span>
                            </div>
                            <div className="awardListBox">
                              <div
                                className="clutch-widget"
                                data-url="https://widget.clutch.co"
                                data-widget-type="2"
                                data-height="45"
                                data-nofollow="true"
                                data-expandifr="true"
                                data-clutchcompany-id="17416"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="marketRight">
                      <div className="projectCounts">
                        <ReviewsSlider />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="contactSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <div className="contactRelative">
                  <div className="contactTitle">
                    <div className="casestudySlider">
                      <h1>
                        <span className="circle"></span>Contact us
                      </h1>
                    </div>
                  </div>
                  <Contact
                    type="static"
                    handleChange={handlePopupModal}
                    key={[popupShow, popupShowContact]}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="ourofficeSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Our offices
                </h1>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ourofficeAddress">
                        <div>
                          <h4>Australia Office</h4>
                          <p>
                            Xminds Solutions | Australia, Suite 1203, 23 Hunter
                            Street, Sydney, NSW, 2000.
                          </p>
                        </div>
                        <div>
                          <h4>Europe Office</h4>
                          <p>
                            TACEMA, Via Garibaldi 9/C, 24122 Bergamo – Italy.
                          </p>
                        </div>
                        <div>
                          <h4>Singapore Office</h4>
                          <p>
                            1 Sophia road #05-23, Peace Centre, Singapore
                            228149.
                          </p>
                        </div>
                        <div>
                          <h4>Canada Office</h4>
                          <p>1160 Tapscott Rd. Scarborough, ON – M1X 1E9.</p>
                        </div>
                        <div>
                          <h4>India Office (HQ)</h4>
                          <p>
                            #T4 & T8, Thejaswini Building, 7th Floor, Phase I,
                            Technopark, Trivandrum, Kerala, India - 695 581.
                          </p>
                        </div>
                        <div>
                          <h4>India Office (BO)</h4>
                          <p>
                            3rd floor, Artech Samrudhi Magnet Vazhuthacaud,
                            Trivandrum, Kerala, India - 695 014.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="locationMap">
                        <CustomMap />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Popup show={popupShow} handleClose={handlePopupModal} body="success" />
        <Popup
          show={popupShowContact}
          handleClose={handlePopupModalContact}
          body="contactForm"
        />
      </Layout>
    </>
  );
};
export const Head = ({ pageContext }) => {
  const dynamicContent = pageContext;
  return (
    <Seo
      title={dynamicContent?.meta_title}
      description={dynamicContent?.meta_content}
      url={`/${dynamicContent?.url}`}
    />
  );
};
export default ServiceDynamicPage;
