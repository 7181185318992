import React, { useEffect, useState, useRef, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Context } from "../hooks/globalState";

import { AnchorLink } from "gatsby-plugin-anchor-links";
export default function OfferSlider(props) {
  const slider = useRef();
  const [state, setState] = useState({ data: props?.data });
  const [subUrlId, setSubUrlId] = useContext(Context);
  const settings = {
    centerMode: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setSubUrlId(localStorage.getItem("url_id"));
    slider.current.slickGoTo(localStorage.getItem("url_id"));
  }, []);

  useEffect(() => {
    slider.current.slickGoTo(subUrlId);
  }, [subUrlId]);
  useEffect(() => {
    setSubUrlId(localStorage.getItem("url_id"));
    slider.current.slickGoTo(localStorage.getItem("url_id"));
    setState({ data: props?.data })
  }, [props]);

  return (
    <Slider {...settings} ref={slider} key={subUrlId}>
      {props?.data?.map(({ title, data, index, url }) => {
        return (
          <div className="authorBox" key={title}>
            <div className="testimonialBoxItem">
              <div className="testimonialBoxContent">
                <div className="testimonialAuthor">
                  {url ? <AnchorLink
                    to={`/services/${url}/`}
                    title={title}
                    key={index}
                    className="anchor-link"
                  >
                    <h5>{title}</h5>
                  </AnchorLink> : <h5>{title}</h5>}

                </div>
              </div>
              {data?.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          </div>
        );
      })}
    </Slider>
  );
}
